<template>
    <div class="privacy">
        <div class="header-wrapper d-flex justify-content-center align-items-center">
            <div class="container text-center header-container-sm">
                <h1 class="text-light">Datenschutz<wbr>erklärung
                </h1>
            </div>
        </div>

        <b-container class="container mt-5">
            <p>
                Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb. gemäß BDSG
                n.F. und der europäischen Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und Zweck der
                Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese Datenschutzerklärung gilt auch für
                unsere Websites und Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie etwa
                „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf Art. 4 DS-GVO.
            </p>
            <p>
                <strong>Verantwortlicher im Sinne der Datenschutzgesetze, <br>
                    insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</strong><br>
                Rufus Maiwald<br>
                Riemerschmidstraße 7<br>
                82319 Starnberg<br>
                privacy@mcone.eu<br>
            </p>

            <h4>Erfassung allgemeiner Informationen beim Besuch unserer Website</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig
                Informationen
                übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen
                (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den
                Domainnamen
                Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
            <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
            <ul>
                <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art
                werden
                von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu
                optimieren.</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an
                der
                Verbesserung der Stabilität und Funktionalität unserer Website.</p>
            <strong>Empfänger:</strong>
            <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer
                Webseite
                als Auftragsverarbeiter tätig werden.</p>
            <strong>Speicherdauer:</strong>
            <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist
                für
                die Daten, die der Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die jeweilige
                Sitzung
                beendet ist.</p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
                vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website
                nicht
                gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus
                diesem Grund ist ein Widerspruch ausgeschlossen. </p>
            <p></p>
            <h4>Registrierung auf unserer Website</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Bei der Registrierung für die Nutzung unserer personalisierten Leistungen werden einige personenbezogene
                Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten (z. B. Telefonnummer und
                E-Mail-Adresse). Sind Sie bei uns registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir
                nur
                registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die Möglichkeit, bei Bedarf die bei
                Registrierung angegebenen Daten jederzeit zu ändern oder zu löschen. Selbstverständlich erteilen wir
                Ihnen
                darüber hinaus jederzeit Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten.</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung
                des
                Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</p>
            <p>Dient die Registrierung der Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist
                oder
                der Durchführung vorvertraglicher Maßnahmen, so ist zusätzliche Rechtsgrundlage für die Verarbeitung der
                Daten Art. 6 Abs. 1 lit. b DSGVO.</p>
            <strong>Empfänger:</strong>
            <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer
                Website
                als Auftragsverarbeiter tätig werden.</p>
            <strong>Speicherdauer:</strong>
            <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt.
                Danach
                werden sie gelöscht, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur
                Kontaktaufnahme in
                diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung angegebenen
                Kontaktdaten.</p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung.
                Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere
                angebotenen
                Inhalte und Leistungen gewähren.</p>
            <p></p>
            <h4>Erbringung kostenpflichtiger Leistungen</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B.
                Zahlungsangaben, um Ihre Bestellung ausführen zu können.</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Die Verarbeitung der Daten, die für den Abschluss des Vertrages erforderlich ist, basiert auf Art. 6 Abs.
                1
                lit. b DSGVO.</p>
            <strong>Empfänger:</strong>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
            <strong>Speicherdauer:</strong>
            <p>Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.
                Diese betragen grundsätzlich 6 oder 10 Jahre aus Gründen der ordnungsmäßigen Buchführung und
                steuerrechtlichen Anforderungen.</p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer
                personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotenen Inhalte und Leistungen
                gewähren.</p>
            <p></p>
            <h4>Kommentarfunktion</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Wenn Nutzer Kommentare auf unserer Website hinterlassen, werden neben diesen Angaben auch der Zeitpunkt
                ihrer
                Erstellung und der zuvor durch den Websitebesucher gewählte Nutzername gespeichert. Dies dient unserer
                Sicherheit, da wir für widerrechtliche Inhalte auf unserer Webseite belangt werden können, auch wenn
                diese
                durch Benutzer erstellt wurden.</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Die Verarbeitung der als Kommentar eingegebenen Daten erfolgt auf der Grundlage eines berechtigten
                Interesses
                (Art. 6 Abs. 1 lit. f DSGVO).</p>
            <p>Durch Bereitstellung der Kommentarfunktion möchten wir Ihnen eine unkomplizierte Interaktion ermöglichen.
                Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
                gespeichert.</p>
            <strong>Empfänger:</strong>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
            <strong>Speicherdauer:</strong>
            <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist
                grundsätzlich der Fall, wenn die Kommunikation mit dem Nutzer abgeschlossen ist und das Unternehmen den
                Umständen entnehmen kann, dass der betroffene Sachverhalt abschließend geklärt ist.</p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer
                personenbezogenen Daten können wir Ihnen keinen Zugang zu unserer Kommentarfunktion gewähren.</p>
            <p></p>
            <h4>Newsletter</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Ihre Daten werden ausschließlich dazu verwendet, Ihnen den abonnierten Newsletter per E-Mail zuzustellen.
                Die
                Angabe Ihres Namens erfolgt, um Sie im Newsletter persönlich ansprechen zu können und ggf. zu
                identifizieren, falls Sie von Ihren Rechten als Betroffener Gebrauch machen wollen.</p>
            <p>Für den Empfang des Newsletters ist die Angabe Ihrer E-Mail-Adresse ausreichend. Bei der Anmeldung zum
                Bezug
                unseres Newsletters werden die von Ihnen angegebenen Daten ausschließlich für diesen Zweck verwendet.
                Abonnenten können auch über Umstände per E-Mail informiert werden, die für den Dienst oder die
                Registrierung
                relevant sind (bspw. Änderungen des Newsletterangebots oder technische Gegebenheiten).</p>
            <p>Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass eine
                Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
                „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die Bestellung des Newsletters, den Versand
                einer
                Bestätigungsmail und den Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht erhoben.
                Die
                Daten werden ausschließlich für den Newsletterversand verwendet und nicht an Dritte weitergegeben.</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), übersenden wir
                Ihnen
                regelmäßig unseren Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre angegebene
                E-Mail-Adresse. </p>
            <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand
                können
                Sie jederzeit mit Wirkung für die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein
                entsprechender Link. Außerdem können Sie sich jederzeit auch direkt auf dieser Website abmelden oder uns
                Ihren Widerruf über die am Ende dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.</p>
            <strong>Empfänger:</strong>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
            <strong>Speicherdauer:</strong>
            <p>Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt.
                Danach werden sie gelöscht.</p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung.
                Ohne bestehende Einwilligung können wir Ihnen unseren Newsletter leider nicht zusenden.</p>
            <p></p>
            <h4>Kontaktformular</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert.
                Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der
                Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist
                optional.</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines
                berechtigten
                Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
            <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme
                ermöglichen.
                Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
                gespeichert.</p>
            <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das
                Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b
                DSGVO).</p>
            <strong>Empfänger:</strong>
            <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
            <strong>Speicherdauer:</strong>
            <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
            <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach
                HGB
                und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur
                bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
            <p></p>
            <h4>Verwendung von Google Analytics</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043 USA (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also
                Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite
                durch
                Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden
                in
                der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der
                Aktivierung
                der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von
                Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                Europäischen
                Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von
                Google in
                den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
                Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die
                Webseitenaktivitäten
                zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
                Dienstleistungen
                gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
                übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
            <p>Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website und in der
                Zusammenstellung
                von Reports über Aktivitäten auf der Website. Auf Grundlage der Nutzung der Website und des Internets
                sollen
                dann weitere verbundene Dienstleistungen erbracht werden.</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Die Verarbeitung der Daten erfolgt auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a
                DSGVO).</p>
            <strong>Empfänger:</strong>
            <p>Empfänger der Daten ist Google als Auftragsverarbeiter. Hierfür haben wir mit Google den entsprechenden
                Auftragsverarbeitungsvertrag abgeschlossen.</p>
            <strong>Speicherdauer:</strong>
            <p>Die Löschung der Daten erfolgt, sobald diese für unsere Aufzeichnungszwecke nicht mehr erforderlich
                sind.</p>
            <strong>Drittlandtransfer:</strong>
            <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a
                    href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.
            </p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung.
                Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der Website
                kommen.</p>
            <strong>Widerruf der Einwilligung:</strong>
            <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
                verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung
                der
                durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an
                Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden
                Link
                verfügbare Browser-Plugin herunterladen und installieren: <a
                        href="http://tools.google.com/dlpage/gaoptout?hl=de">Browser Add On zur Deaktivierung von Google
                    Analytics</a>.</p>
            <p>Zusätzlich oder als Alternative zum Browser-Add-On können Sie das Tracking durch Google Analytics auf
                unseren
                Seiten unterbinden, indem Sie <a title="Google Analytics Opt-Out-Cookie setzen"
                                                 onClick="gaOptout();alert('Google Analytics wurde deaktiviert');"
                                                 href="#">diesen
                    Link anklicken</a>. Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die
                Erfassung
                durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, so lange das
                Cookie in
                Ihrem Browser installiert bleibt.</p>
            <strong>Profiling:</strong>
            <p>Mit Hilfe des Tracking-Tools Google Analytics kann das Verhalten der Besucher der Webseite bewertet und
                die
                Interessen analysiert werden. Hierzu erstellen wir ein pseudonymes Nutzerprofil.</p>
            <p></p>
            <h4>Verwendung von Scriptbibliotheken (Google Webfonts)</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf
                dieser
                Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
                nachfolgend „Google“) zur Darstellung von Schriften.</p>
            <p>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a
                    href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
            <strong>Rechtsgrundlage:</strong>
            <p>Rechtsgrundlage für die Einbindung von Google Webfonts und dem damit verbundenen Datentransfer zu Google
                ist
                Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). </p>
            <strong>Empfänger:</strong>
            <p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber
                der
                Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen
                Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.</p>
            <strong>Speicherdauer:</strong>
            <p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Webfonts.</p>
            <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a
                    href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>
                und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
            </p>
            <strong>Drittlandtransfer:</strong>
            <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a
                    href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.
            </p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben.
                Allerdings kann ggfs. die korrekte Darstellung der Inhalte durch Standardschriften nicht möglich
                sein.</p>
            <strong>Widerruf der Einwilligung:</strong>
            <p>Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache JavaScript verwendet. Sie können der
                Datenverarbeitung daher widersprechen, indem Sie die Ausführung von JavaScript in Ihrem Browser
                deaktivieren
                oder einen JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu
                Funktionseinschränkungen auf der Website kommen kann.</p>
            <p></p>
            <h4>Eingebettete YouTube-Videos</h4>
            <strong>Art und Zweck der Verarbeitung:</strong>
            <p>Auf einigen unserer Webseiten betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die
                YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Wenn Sie eine Seite mit
                dem
                YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube
                mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube
                Ihr
                Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem
                YouTube-Account ausloggen.</p>
            <p>Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten
                sammeln.</p>
            <p>Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten
                Sie
                in den Datenschutzerklärungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren
                diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre (<a
                        href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>). Google
                verarbeitet Ihre Daten in den USA und hat sich dem EU-US Privacy Shield unterworfen
                https://www.privacyshield.gov/EU-US-Framework</p>
            <strong>Rechtsgrundlage:</strong>
            <p>Rechtsgrundlage für die Einbindung von YouTube und dem damit verbundenen Datentransfer zu Google ist Ihre
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
            <strong>Empfänger:</strong>
            <p>Der Aufruf von YouTube löst automatisch eine Verbindung zu Google aus.</p>
            <strong>Speicherdauer und Widerruf der Einwilligung:</strong>
            <p>Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von
                YouTube-Videos mit keinen solchen Cookies rechnen müssen. YouTube legt aber auch in anderen Cookies
                nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, so müssen Sie das
                Speichern
                von Cookies im Browser blockieren.</p>
            <p>Weitere Informationen zum Datenschutz bei „YouTube“ finden Sie in der Datenschutzerklärung des Anbieters
                unter: <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/ </a>
            </p>
            <strong>Drittlandtransfer:</strong>
            <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a
                    href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.
            </p>
            <strong>Bereitstellung vorgeschrieben oder erforderlich:</strong>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung.
                Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschränkungen auf der Website
                kommen.</p>
            <p></p>
            <h4>SSL-Verschlüsselung</h4>
            <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der
                Technik
                entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
            <p></p>
            <h4>Änderung unserer Datenschutzbestimmungen</h4>
            <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
                Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
                z.B.
                bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue
                Datenschutzerklärung.</p>
            <h4>Fragen an den Datenschutzbeauftragten</h4>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an
                die
                für den Datenschutz verantwortliche Person in unserer Organisation:</p>
            <p>privacy@mcone.eu</p>
            <small class="text-muted"><em>
                Quelle: <a class="text-muted" href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                           target="_blank" rel="noopener">activeMind AG</a>
            </em></small>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Privacy"
    }
</script>

<style scoped>
    .header-wrapper {
        background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.8),
                rgba(55, 55, 55, 0.75)
        );
        background-size: cover;
        padding-top: 50px;
    }

    .header-container-sm {
        padding: 75px 0;
    }
</style>
